#product_box {
  width: 250px;
  height: 326px;
  border-radius: 14px;
  background: #f5f5f5;
}
#price {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.48px;
  width: 77px;
  height: 29px;

  border-radius: 7px;
  background: #4ecb71;
}
#product_title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-top: 5px;
  margin-bottom: 10px;
}
