#admin_nav {
  height: 110px;
  background: #f2ffde;
  display: flex;
}
#logo_name_box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 40px;
  margin-top: 10px;
}
#logo_name_box #name {
  color: #52771a;
  font-family: Krona One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
